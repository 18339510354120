import React from 'react';
import useStyles from '../../styles/homeFullWidthSlideshowStyle';
import { Grid, Typography, Button } from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TitleWithShortLine from '../common/TitleWithShortLine';
import spaceImg from '../../assets/images/home_space.jpg';
import serviceImg from '../../assets/images/home_service.jpg';
import eventImg from '../../assets/images/home_event.jpg';


const HomeFullWidthSlideshow = () => {
    const classes = useStyles();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
    };
    return(
        <Slider {...settings}>
          <Grid container className={classes.slideshowTextSection}>
            <Grid item lg={5} className={classes.whiteBgSection}>
              <div>
                <TitleWithShortLine titleText="環境介紹" />
                <Typography variant="h1" className={classes.slideshowTitle}>會談空間</Typography>
                <Typography variant="h2" className={classes.slideshowText}>我們重視談話的氛圍，盡可能地降低干擾，讓你能自在地訴說。</Typography>
                <Button href="/space">More</Button>
              </div>
            </Grid>
            <Grid item className={classes.slideshowImg}>
              <div className={classes.slideshowImgWrap}>
                <img src={spaceImg} alt="環境介紹圖" />
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.slideshowTextSection}>
            <Grid item lg={5} className={classes.whiteBgSection}>
              <div>
                <TitleWithShortLine titleText="服務及收費" />
                <Typography variant="h1" className={classes.slideshowTitle}>服務及收費</Typography>
                <Typography variant="h2" className={classes.slideshowText}>依據每個人不同的需求及想討論的心理主題，我們提供多元的專業服務。</Typography>
                <Button href="/service">More</Button>
              </div>
            </Grid>
            <Grid item className={classes.slideshowImg}>
              <div className={classes.slideshowImgWrap}>
                <img src={serviceImg} alt="服務及收費" />
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.slideshowTextSection}>
            <Grid item lg={5} className={classes.whiteBgSection}>
              <div>
                <TitleWithShortLine titleText="最新活動" />
                <Typography variant="h1" className={classes.slideshowTitle}>最新活動</Typography>
                <Typography variant="h2" className={classes.slideshowText}>不只提供心理治療的服務,同時,我們不定期舉辦心理教育講座及工作坊的活動,詳情請上FB粉絲頁。</Typography>
                <a className="MuiButtonBase-root MuiButton-root MuiButton-text" href="https://www.facebook.com/pg/1010psy.tw/events/?ref=page_internal">More</a>
              </div>
            </Grid>
            <Grid item className={classes.slideshowImg}>
              <div className={classes.slideshowImgWrap}>
                <img src={eventImg} alt="活動介紹圖" />
              </div>
            </Grid>
          </Grid>
        </Slider>       
    )
}

export default HomeFullWidthSlideshow;