import React from 'react';
import PageTitleAndSubtitle from '../components/common/PageTitleAndSubtitle';
import SupervisorForm from '../components/member/SupervisorForm';
import { supervisorsEmptyValues } from '../constants/formInitialValues';
import BackToPreviousPageButton from '../components/common/BackToPreviousPageButton';

const SupervisorAddPage = () => {

    return(
     <>
        <BackToPreviousPageButton 
            link="/admin/supervisors"
            txt="回督導列表"
        />
        <PageTitleAndSubtitle
            title="新增督導"
        />
        <SupervisorForm
            initialValues={supervisorsEmptyValues}
        />
     </>   
    )
}

export default SupervisorAddPage;
