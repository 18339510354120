import { createTheme } from '@material-ui/core/styles';

// Custom Mui Theme for our project
const theme = createTheme({
    palette: {
      primary: {
        main: '#FFE451'
      },
      secondary: {
        main: '#B7C5D5'
      },
      error: {
        main: '#f44336'
      },
    },
    shape: {
      borderRadius: 13,
    },
    overrides: {
      MuiCard: {
        root: {
          boxShadow: 'none',
        }
      },
      MuiButton: {
        root: {
          boxShadow: 'none !important',
        }
      }
    }
})

export default theme;