import React from 'react';
import { Grid, Box, Typography, Chip, Avatar } from '@material-ui/core';
import { PermContactCalendarRounded, GestureRounded, StarRateRounded, FaceRounded } from '@material-ui/icons';

export const TitlesOnList = props => {

    const { mainTitle, subTitle } = props;

    const handleChipAvatar = title => {

        switch (true) {
            case new RegExp('臨床心理師').test(title):
                return <PermContactCalendarRounded fontSize="small" className="coral" />;
            case new RegExp('博士').test(title):
                return<StarRateRounded fontSize="small" className="chartreuse" />;
            case new RegExp('藝術治療').test(title):
                return  <GestureRounded fontSize="small" className="hotpink" />;
            case new RegExp('創辦人').test(title):
                return <FaceRounded fontSize="small" className="royalblue" />;
          default:
                return;
        }
    }

    return (
        <Grid container alignItems="center" direction="row" spacing={1}>
            <Grid item xs={12} sm={12} md={2}>
                <Typography variant="caption" color="secondary">
                    職稱｜
                </Typography>
            </Grid>
            <Grid item>
                {mainTitle && 
                    <Chip 
                        avatar={<Avatar>{handleChipAvatar(mainTitle)}</Avatar>}
                        variant="outlined" 
                        label={mainTitle} 
                        className="title-chip"
                    />
                }
            </Grid>
            <Grid item>
                {subTitle && 
                    <Chip 
                        avatar={<Avatar>{handleChipAvatar(subTitle)}</Avatar>}
                        variant="outlined" 
                        label={subTitle}  
                        className="title-chip" 
                    />
                }
            </Grid>
        </Grid>
    )
} 

export const NumberOnList = props => {

    const { number } = props;

    return (
        <Box display="flex" alignContent="center">
            <Box mr={1}>
                <Typography variant="body2" color="textPrimary">
                    {number}
                </Typography>
            </Box>
        </Box>
    )
} 
