import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../styles/aboutStyle';
import PCHeader from '../components/common/PCHeader';
import Footer from '../components/common/Footer';
import aboutUs from '../assets/images/about.jpg'

const About = () => {
    const classes = useStyles();
    return(
        <div>
            <PCHeader />
            <div className={classes.aboutWrapper}>
                <Grid container className={classes.pageTitle} alignItems="center" justifyContent="center">
                    <Grid item className={classes.pageTitleText}>
                        <Typography variant="h5">關於我們</Typography>
                        <span></span>
                    </Grid>
                </Grid>
                <div className={classes.slogan}>
                    <Typography variant="h3" align="center">「心理治療是一段取捨而拾獲的旅程，</Typography>
                    <Typography variant="h3" align="center">從月缺到月圓的日常。」</Typography>
                </div>
                <img src={aboutUs} alt="關於我們"  className={classes.aboutImg} />
                <Typography variant="h2">
                    How psychotherapy service becomes a positive impact in our society
                </Typography>
                <Typography variant="body1">
                    心理治療所如何成為社區的一份子? 
                    <br></br>
                    心理治療所是否能成為社區裡心理健康的影響據點?
                    <br></br>
                    心理治療所能否成為社會創新的一股新力量?
                </Typography>
                <Typography variant="body1">
                    我們透過每一次的心理治療、講座及工作坊，思考治療師、個體與社區的共存關係，治療師團隊以持續精進的專業基礎及重視人本的深厚底蘊，與個體一同省思、一同成長，致力於提升個體的生活品質，並擴散於重要他人與所處社區/社群，進一步為社會帶來心理健康的正面影響。
                </Typography>
            </div>
            <Footer />
        </div>
    )
}

export default About;