import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    keyVisualWrapper: {
        position: 'relative',
        height: '70vh',
        minHeight: 500,
        overflow: 'hidden',
        top: 50,
        marginBottom: 50,
        '& img':{
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            height: 'initial',
            padding: '18% 0 10%',
            '& img':{
                height: '100%',
            },
        },
    },
    dotLogoImg: {
        width: '60% !important',
        maxHeight: '50vh',
        [theme.breakpoints.down('sm')]: {
            width: '70% !important',
            marginBottom: theme.spacing(6)
        },
    },
    keyVisual: {
        position: 'relative',
        flexGrow: 1,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 8%', 
        zIndex: 3,
        [theme.breakpoints.down('sm')]: {
            padding: '0 13%',
        }
    },
    textLogo: {
        marginBottom: '30%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
            maxWidth: 200, 
        },
    },
    slogan: {
        '& p': {
            letterSpacing: '0.3em',
            marginRight: -20,
            lineHeight: '1.8em',
            textAlign: 'right',
            wordWrap: 'break-word',
            [theme.breakpoints.down('sm')]: {
                letterSpacing: '0.2em',
                width: 280,
            },
        }
    },
    keyVisualBG: {
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    }
}));

export default useStyles;