import React from 'react';
import PageTitleAndSubtitle from '../components/common/PageTitleAndSubtitle';
import PsychologistForm from '../components/member/PsychologistForm';
import { psychologistsEmptyValues } from '../constants/formInitialValues';
import BackToPreviousPageButton from '../components/common/BackToPreviousPageButton';

const PsychologistAddPage = () => {

    return(
     <>
        <BackToPreviousPageButton 
            link="/admin/psychologists"
            txt="回心理師列表"
        />
        <PageTitleAndSubtitle
            title="新增心理師"
        />
        <PsychologistForm
            initialValues={psychologistsEmptyValues}
        />
     </>   
    )
}

export default PsychologistAddPage;
