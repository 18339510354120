import React from 'react';
import { List } from '@material-ui/core';
import useStyles from '../../styles/drawerStyle';
import ListItemLink from '../drawer/ListItemLink';
import { drawerList } from '../../constants/drawerList';

const DrawerList = () => {

    const classes = useStyles();

    return (
        <List  className={classes.drawerList}>
            {
                drawerList.map( (o, i) => {
                    return (
                        <ListItemLink 
                            key={i}
                            to={o.link}
                            icon={o.icon}
                            text={o.text}
                        />
                    )
                })
            }
            
        </List>
    )
}

export default DrawerList;