import React from "react";
import { Box, Typography } from "@material-ui/core";
import { EmojiEmotionsOutlined } from "@material-ui/icons";

export const FormSectionTitle = props => {
  const { title, tip } = props;

  return (
    <Box mb={4} className="form-title-with-tip">
      <Typography variant="h6">
        {title}
      </Typography>
      {tip &&
        <Box display="flex" alignItems="center">
          <Box mr="5px" display="span">
            <EmojiEmotionsOutlined fontSize="small" />
          </Box>
          <Typography variant="caption">
            {tip}
          </Typography>
        </Box>}
    </Box>
  );
};

export const TipOnly = props => {
  const { tip } = props;

  return (
    <Box display="flex" alignItems="center" mt={1} mb={1}>
      <Box mr="5px" display="span">
        <EmojiEmotionsOutlined fontSize="small" />
      </Box>
      <Typography variant="caption">
        {tip}
      </Typography>
    </Box>
  );
};