import React, { useEffect, useState } from "react";
import defaultImage from "../../../assets/images/default_image.svg";
import _ from 'lodash';

// for handle form onChange and validation
export const useForm = (initialValues, validateOnChange = false, validate) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [initAvatar, setInitAvatar] = useState(defaultImage);
  const [isFileSizeError, setIsFileSizeError] = useState(false);
  const [isFileExtensionError, setIsFileExtensionError] = useState(false);

  const handleInputChange = (e, inputType, avatarFile) => {
    
    if (inputType !== 'avatar' && inputType !== 'checkbox') {
      const { name, value } = e.target;

      setValues({
        ...values,
        [name]: value,
      });

      if (validateOnChange) {
        validate({ [name]: value });
      }
    }

    if (inputType === 'avatar') {
      setValues({
        ...values,
        avatar: { file: avatarFile.file, src: avatarFile.src },
      });

      if (validateOnChange) {
        validate({ avatar: avatarFile });
      }
    }

    if (inputType === 'checkbox') {
      const { name, label, checked } = e.target;
      const originalKeywords = values.keywords;
      let newKeywords = !checked ? [...originalKeywords, {name: name, label: label}] : _.reject(originalKeywords, {name: name});
      setValues({
        ...values,
        keywords: newKeywords
      });
    }
  };

  const resetForm = () => {
    setValues(initialValues);
    setErrors({});
    setInitAvatar(defaultImage);
    setIsFileSizeError(false);
    setIsFileExtensionError(false);
  };

  useEffect(() => {
    setValues(initialValues); // for initial values 
  }, [initialValues]);

  useEffect(()=>{
    setValues(values); // for values updated from PsychologistForm or SupervisorForm
  },[values])

  return {
    values,
    setValues,
    defaultImage,
    initAvatar,
    setInitAvatar,
    isFileSizeError,
    setIsFileSizeError,
    isFileExtensionError,
    setIsFileExtensionError,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
};

export const Form = (props) => {
  const { children, ...other } = props;

  return (
    <form autoComplete="off" {...other}>
      {children}
    </form>
  );
};
