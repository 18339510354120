import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    aboutWrapper: {
        paddingBottom: theme.spacing(9),
        '& h3': {
            fontSize: 30,
            lineHeight: '1.5em',
            letterSpacing: '0.07em',
            margin: '0 10%',
            textAlign: 'center',
        },
        '& h2': {
            fontFamily: 'Gerogia',
            width: '50%',
            fontSize: 40,
            minWidth: 200,
            margin: '0 auto 64px',
            textAlign: 'center',
            paddingBottom: theme.spacing(8),
            borderBottom: '3px solid #FFE451',
        },
        '& p': {
            width: '50%',
            minWidth: 200,
            lineHeight: '1.7em',
            letterSpacing: '0.07em',
            margin: '0 auto 32px',
        }
    },
    slogan: {
        marginBottom: theme.spacing(15),
    },  
    aboutImg: {
        width: '100%',
        marginBottom: theme.spacing(8),
    },
    pageTitle: {
        height: 160,
        position: 'relative',
        top: 50,
        overflow: 'hidden',
        marginBottom: theme.spacing(12),
    },
    pageTitleText: {
        position: 'relative',
        zIndex: 4,
        '& h5': {
            letterSpacing: '0.2em'
        },
        '& span': {
            display: 'block',
            margin: '16px auto 0',
            width: 20,
            height: 1,
            background: '#000'
        }
    },
}));

export default useStyles;