import { Divider, Grid, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import app from "../../config/base";
import { onDragEnd } from "../../helpers/handleDnDReorder";
import {
  AddMemberButton,
  AddMemberIconButton,
  SaveOrderButton,
  SaveOrderIconButton,
} from "../components/common/Buttons";
import PageTitleAndSubtitle from "../components/common/PageTitleAndSubtitle";
import SupervisorListItem from "../components/member/SupervisorListItem";

const Supervisors = () => {
  const [initialItems, setInitialItems] = useState([]);
  const [items, setItems] = useState([]);
  const [areItemsReordered, setAreItemsReordered] = useState(false);
  const [isOrderSaving, setIsOrderSaving] = useState(false);

  const saveReorderedItems = () => {
    setIsOrderSaving(true);
    const setSequence = async () => {
      await Promise.all(
        items.map((supervisior, sequence) =>
          app.firestore().collection("supervisiors").doc(supervisior.id).update({ sequence }),
        ),
      );
      setIsOrderSaving(false);
      setInitialItems(items);
      setAreItemsReordered(false);
    };
    setSequence();
  };

  useEffect(() => {
    const loadSupervisior = async () => {
      const snapshots = await app.firestore().collection("supervisiors").orderBy("sequence", "asc").get();

      const supervisiors = snapshots.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          img_src: data["avatar_url"],
          name: data["name"],
          main_title: data["title"],
          sub_title: data["title_second"],
        };
      });

      setItems(supervisiors);
      setInitialItems(supervisiors);
    };
    loadSupervisior();
  }, []);

  return (
    <>
      <Grid container spacing={2} alignItems="center" className="justifyContentSpaceBetween">
        <Grid item>
          <PageTitleAndSubtitle title="督導成員" subtitle="編輯、新增及刪除所內督導成員" />
        </Grid>
        <Grid item>
          <Hidden smDown>
          <SaveOrderButton txt={isOrderSaving ? "已儲存！" : "儲存順序"} onClick={ isOrderSaving ? null : saveReorderedItems } disabled={!areItemsReordered} />
            <AddMemberButton link="/admin/supervisor_add" txt="新增督導" />
          </Hidden>
        </Grid>
        <Hidden mdUp>
          <AddMemberIconButton link="/admin/supervisor_add" />
          <SaveOrderIconButton onClick={saveReorderedItems} disabled={!areItemsReordered} />
        </Hidden>
      </Grid>
      <Divider className="mb_16" />
      <DragDropContext onDragEnd={(result) => onDragEnd(result, items, setItems, initialItems, setAreItemsReordered)}>
        <Droppable droppableId="supervisors">
          {(provided) => (
            <Grid container spacing={2} className="psychologists" {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((o, i) => (
                <Draggable key={o.id} draggableId={o.id} index={i}>
                  {(provided) => (
                    <Grid
                      item
                      xs={12}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <SupervisorListItem
                        imgSrc={o.img_src}
                        name={o.name}
                        mainTitle={o.main_title}
                        subTitle={o.sub_title}
                        id={o.id}
                      />
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default Supervisors;
