import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useStyles from '../../styles/spaceStyle';

const SpaceItem = (props) => {
    const classes = useStyles();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
    };
    return(
        <Grid container className={classes.spaceItem} spacing={8} alignItems="center" justifyContent="center" direction={props.flexDirection}>
            <Grid item sm={6} xs={12}>
                <Slider {...settings}>
                    <img src={props.imgLink} alt={props.imgAlt} />
                    <img src={props.imgLink2} alt={props.imgAlt2} />
                </Slider>
            </Grid>
            <Grid item sm={6} xs={12}>
                <Typography variant="h5" className={classes.spaceTitle}>{props.spaceTitle}</Typography>
                <Typography variant="body1" className={classes.spaceDescription}>{props.spaceDescription}</Typography>
            </Grid>
        </Grid>
    )
}

export default SpaceItem;