import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    footer: {
      flexGrow: 1,
      zIndex: 9999,
      position: 'relative',
      background: '#fff',
      height: 'initial',
      padding: '2% 8% 0', 
      marginTop: -10,
      '& .MuiTypography-colorTextSecondary': {
          color: '#aaa',
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(10)
      },
      [theme.breakpoints.down('sm')]: {
          height: 'initial',
          flexDirection: 'column-reverse',
      }
    },
    logo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        '& a': {
            height: '150px',
            display: 'block',
            marginRight: theme.spacing(3),
            '& img': {
                height: '70%',
            }
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: theme.spacing(5),
            '& a': {
                marginRight: 0,
                height: 'initial',
            },
            '& img': {
                width: '100%',
                height: 'auto !important',
                marginBottom: theme.spacing(3),
            }
        },  
    },
    location: {
        '& h5': {
            marginBottom: theme.spacing(3),
            color: '#666 !important'
        },
        '& p': {
            marginBottom: theme.spacing(1),
            color: '#666 !important'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
            textAlign: 'center', 
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        }
    }
}));

export default useStyles;