import React from "react";
import { Link } from "react-router-dom";
import useStyles from "../../styles/navStyle";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import FavoriteIcon from "@material-ui/icons/Favorite";
import GroupIcon from "@material-ui/icons/Group";
import EventSeatIcon from "@material-ui/icons/EventSeat";
// import EventIcon from "@material-ui/icons/Event";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const BottomNav = props => {
  const classes = useStyles();

  return (
    <BottomNavigation showLabels className={classes.bottomNav}>
      <BottomNavigationAction
        component={Link}
        to="/service"
        label="服務介紹"
        icon={<FavoriteIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/team"
        label="團隊介紹"
        icon={<GroupIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/space"
        label="環境介紹"
        icon={<EventSeatIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/about"
        label="關於我們"
        icon={<FiberManualRecordIcon />}
      />
      {/* <BottomNavigationAction
        component={Link}
        to="/booking"
        label="線上預約"
        icon={<EventIcon />}
      /> */}
    </BottomNavigation>
  );
};

export default BottomNav;
