import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, Typography, DialogActions } from '@material-ui/core';
import { DeleteOutlineOutlined } from "@material-ui/icons";
import LoadingIndicator from '../../../components/common/LoadingIndicator';

const DeleteConfirmDialog = props => {
    const { name, onClose, open, handleDelete, isDeleting } = props;
  
    return (
      <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
          {!isDeleting ? 
            <>
                <DialogTitle variant="body1">刪除</DialogTitle>
                <DialogContent>
                    <Typography>你確定要刪除 {name} 嗎？</Typography>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="center" width="100%" mb={2}>
                        <Button onClick={handleDelete} startIcon={<DeleteOutlineOutlined />}>
                            確定
                        </Button>
                        <Button onClick={onClose}>
                                取消
                        </Button>
                    </Box>
                </DialogActions>
            </>
            :
            <Box width="200px">
                <LoadingIndicator />
            </Box>
          }
      </Dialog>
    );
  }

  export default DeleteConfirmDialog;