import Input from './Input';
import Checkbox from './Checkbox';
import Button from './Button';

const Controls = {
    Input,
    Checkbox,
    Button
}

export default Controls;