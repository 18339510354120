import React, { useState } from "react";
import { Avatar, Card, CardContent, Grid, IconButton } from "@material-ui/core";
import { DeleteOutlineOutlined, EditOutlined } from "@material-ui/icons";
import { deletePsychologist } from "../../../helpers/firestoreHelper";
import Name from "../common/Name";
import { TitlesOnList } from "../common/Titles";
import DeleteConfirmDialog from "../common/DeleteConfirmDialog";

const PsychologistListItem = (props) => {
  const { name, mainTitle, subTitle, id, imgSrc } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  }

  const handleDelete = (e) => {
    e.preventDefault();
    setIsDeleting(true);
    (async () => deletePsychologist(id))();
  };

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Grid container alignItems="center" spacing={4} justifyContent="space-evenly">
            <Grid item xs sm={3} md container justifyContent="center">
              <Avatar src={imgSrc} alt={name} />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <Name txt={name} />
            </Grid>
            <Grid item xs={12} sm={4} md={5} lg={6}>
              <TitlesOnList mainTitle={mainTitle} subTitle={subTitle} />
            </Grid>
            <Grid item xs={12} sm={12} md container justifyContent="center" alignItems="center">
              <IconButton href={`/admin/psychologist_edit/${id}`}>
                <EditOutlined />
              </IconButton>
              <IconButton onClick={handleOpenDialog}>
                <DeleteOutlineOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DeleteConfirmDialog 
        name={name}
        isDeleting={isDeleting}
        open={openDialog} 
        onClose={handleDialogClose} 
        handleDelete={handleDelete} 
      />
    </>
  );
};

export default PsychologistListItem;
