import React from 'react';
import { TextField } from '@material-ui/core';

const Input = props => {

    const { label, name, value, onChange, error = null, multiline, fullWidth, type, className } = props;

    return (
        <TextField
            variant="outlined"
            type={type || 'text'}
            fullWidth={fullWidth || false }
            multiline={multiline || false}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            className={className && className}
            {...(error && {error:true,helperText:error})}
        />
    )
}

export default Input;