import React, { useState } from "react";
import Iframe from "react-iframe";
import useStyles from "../../styles/homeStyle";
import { Box, Typography, Chip } from "@material-ui/core";
import { locationAndUrl } from "../../config/locationAndUrl";
import { PinDropOutlined } from "@material-ui/icons";

const GoogleMap = () => {
  const classes = useStyles();
  const [value, setValue] = useState(locationAndUrl[0]);

  const handleChange = (location) => {
    setValue(locationAndUrl.find((o) => o.location === location));
  };
  return (
    <Box display="flex" flexDirection="column" className={classes.map}>
      <Box mb={4} ml="8%" mr="8%" display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body2" className="pr-2">
          <PinDropOutlined fontSize="small" />
          選擇適合你的分所
        </Typography>
        <Box display="flex" gridGap={10}>
          <Chip
            label="北區"
            disabled={value?.location === "taichung"}
            onClick={() => handleChange("taichung")}
          />
          <Chip
            label="豐原"
            disabled={value?.location === "fengyuan"}
            onClick={() => handleChange("fengyuan")}
          />
        </Box>
      </Box>
      <Iframe
        url={value.url}
        width="100%"
        height="450px"
        id="myId"
        display="initial"
        position="relative"
      />
    </Box>
  );
};

export default GoogleMap;
