// Import FirebaseAuth and firebase.
import React from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { uiConfig } from '../../config/firebaseUIConfig';
import logo from '../../assets/images/logo.svg';

const Login = () => {
  return (
    <Box alignItems="center" display="flex" justifyContent="center" height="100vh">
        <Paper variant="outlined">
          <Box mt={3} mb={1} alignItems="center" display="flex" justifyContent="center" flexDirection="column">
              <img src={logo} alt="logo" className="admin-login-logo" />
          </Box>
          <Typography variant="subtitle1" align="center">
            官網管理後台
          </Typography>
          <Box mb={5}>
              <StyledFirebaseAuth
                uiConfig={uiConfig} 
                firebaseAuth={firebase.auth()} />
          </Box>
        </Paper>
    </Box>
  );
}

export default Login