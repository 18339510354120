import React from 'react';
import { Typography } from '@material-ui/core';

const MemberExperienceText = props => {
    const { info, index } = props;

    return(
        <Typography variant="body2" key={index} color="textPrimary">
            {info}
        </Typography>
    )
}

export default MemberExperienceText;