import React from 'react';
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const ListItemLink = props => {
    const { icon, text, to,  ...other } = props;
    return(
        <ListItem button component={RouterLink} to={to} {...other} >
                <ListItemIcon>
                   {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
            <ListItemText />
        </ListItem>
    )
}

export default ListItemLink;