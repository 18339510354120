import React from 'react';
import ResponsiveDrawer from '../components/drawer/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import '../admin.css';

const CommonLayout = ({children}) => {
  return (
    <div className="flex">
      <CssBaseline />
      <ResponsiveDrawer />
      <main className="flex-grow-1 app-main-content">
        {children}
      </main>
    </div>
  );
}

export default CommonLayout;