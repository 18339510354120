import React from 'react';
import { Typography, Box } from '@material-ui/core';
import useStyles from '../../styles/commonStyle';

const PageTitleAndSubtitle = props => {
    
    const classes = useStyles();
    const {title, subtitle} = props;

    return (
        <Box className={classes.pageTitleAndSubtitle}>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="subtitle1">{subtitle}</Typography>
        </Box>   
    )
}

export default PageTitleAndSubtitle;