import React from 'react';
import useStyles from '../../styles/homeStyle';
import { Typography, Grid } from '@material-ui/core';
import ServiceItem from '../service/ServiceItem';
import { homeServiceFeeObjArray } from '../../constants/servicesData';

const HomeServiceSection = () => {
    const classes = useStyles();
    return(
       <div className={classes.homeServiceSection}>
           <div className={classes.pageTitleText}>
                <Typography variant="h3" align="center">服務介紹</Typography>
                <span></span>
            </div>
            <Grid container spacing={2} alignItems="flex-start" justifyContent="center" >
                <ServiceItem serviceFeeObjArray={homeServiceFeeObjArray}/>
            </Grid>
            <div className={classes.serviceSectionBottomline}></div>
       </div> 
    )
}

export default HomeServiceSection;