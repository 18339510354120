import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../../styles/commonStyle';
import keyVisualBG from '../../assets/images/pc-bg.png';

const PageTitle = (props) => {
    const classes = useStyles()
    return (
        <Grid container className={classes.pageTitle} alignItems="center" justifyContent="center">
            <Grid item className={classes.pageTitleText}>
                <Typography variant="h5">{props.pageTitle}</Typography>
                <span></span>
            </Grid>
            <Grid item className={classes.pageTitleImg}>
               <img src={keyVisualBG} alt="background" />
            </Grid>
        </Grid>
    )
}

export default PageTitle;