import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    spaceItem: {
        padding: '5% 8%',
        maxWidth: '100vw',
    },
    spaceTitle: {
        marginBottom: theme.spacing(4),
    },
    spaceDescription: {
        borderBottom: '4px solid #FFE451',
        paddingBottom: theme.spacing(5)
    }    
}));

export default useStyles;