import React from 'react';
import useStyles from '../../styles/teamStyle';
import { Typography } from '@material-ui/core';

export const MemberNameText = props => {
    const classes = useStyles();
    const { name } = props;

    return(
        <Typography variant="h1" color="textSecondary" className={classes.teamMemberName}>
            {name}
        </Typography>
    )
}

export const MemberTitleText = props => {
    const classes = useStyles();
    const { title } = props;

    return(
        <Typography variant="h2" className={classes.teamMemberTitle}>
            {title}
        </Typography>
    )
}

export const MemberNumberText = props => {
    const { number } = props;

    return(
        <Typography variant="caption" color="textSecondary">
            {number}
        </Typography>
    )
}
