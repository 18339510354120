import _ from 'lodash';

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const onDragEnd = (result, items, setItems, initialItems, setAreItemsReordered) => {
  if (!result.destination) return;

  const newItems = reorder(items, result.source.index, result.destination.index);
  setItems(newItems);
  setAreItemsReordered( !_.isEqual(initialItems, newItems));
};
