import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    header: {
      flexGrow: 1,
      height: 50,
      position: 'fixed',
      zIndex: 9999,
      background: 'rgba(255, 255, 255, 0.7)',
      backdropFilter: 'blur(10px)',
      padding: '0 8%', 
      '& .MuiTypography-colorTextSecondary': {
          color: '#aaa',
      },
      '& .MuiButtonBase-root.Mui-disabled': {
        '& span p': {
          color: '#ddd',
        }
      },
      '& .inline-flex': {
        display: 'inline-flex !important',
        textDecoration: 'none',
        borderRadius: '13px',
        alignItems: 'center !important',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.04)'
        }
      },
      [theme.breakpoints.down('md')]: {
        height: 60,
      }
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
          width: 100,
        }
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    topNav:{
      [theme.breakpoints.down('md')]: {
        display: 'none !important',
      }
    },
    breatheDot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        display: 'inline-block',
        marginRight: 10,
        background: '#FFE451',
    },
    bottomNav: {
      display: 'none !important',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      zIndex: 10000,
      '& .MuiButtonBase-root.Mui-disabled': {
        color: '#ddd',
      },
      '& .MuiBottomNavigationAction-root': {
        minWidth: 'initial',
        padding: '6px 10px 8px',
      },
      '& .MuiBottomNavigationAction-label': {
        fontSize: '0.65rem'
      },
      '& .MuiBottomNavigationAction-label.Mui-selected': {
        fontSize: '0.65rem'
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex !important',
      }
    }
}));

export default useStyles;