import React, { useState } from 'react';
import clsx from 'clsx';
import { Drawer } from '@material-ui/core';
import useStyles from '../../styles/drawerStyle';
import StyledAppBar from '../appbar/StyledAppBar';
import DrawerList from './DrawerList';

const ResponsiveDrawer = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fixedOpen, setFixedOpen] = useState(false);
  
  const handleDrawerToggle = () => {
    setFixedOpen(!fixedOpen);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  }

  const handleDrawerClose = () => {
    setOpen(false);
  }

  return (
    <div className={classes.root}>
      <StyledAppBar
        menuIconOnClick={handleDrawerToggle}        
        open={open}
        fixedOpen={fixedOpen}
      />
      <Drawer
        onMouseOver={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open || fixedOpen,
          [classes.drawerClose]: !open && !fixedOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open || fixedOpen,
            [classes.drawerClose]: !open && !fixedOpen,
          })
        }}
      >
          <DrawerList />
      </Drawer>
    </div>
  )
}

export default ResponsiveDrawer;