import React from 'react';
import { Grid } from '@material-ui/core';
import useStyles from '../styles/serviceStyle';
import PCHeader from '../components/common/PCHeader';
import Footer from '../components/common/Footer';
import ServiceItem from '../components/service/ServiceItem';
import PageTitle from '../components/common/PageTitle';
import { serviceFeeObjArray } from '../constants/servicesData';

const Services = () => {
    const classes = useStyles();
    
    return(
        <div>
            <PCHeader />
            <PageTitle pageTitle="服務及收費"/>
            <Grid container className={classes.serviceItems} spacing={3} direction="row" alignItems="stretch">
                <ServiceItem serviceFeeObjArray={serviceFeeObjArray}/>
            </Grid>
            <Footer />
        </div>
    )
}

export default Services;