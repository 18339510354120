import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    serviceItems: {
        padding: '1% 8% 8%',
        maxWidth: '100vw !important',
        margin: '0 !important',
    },
    serviceItem: {
        marginBottom: theme.spacing(4),
        '& .MuiTypography-body1': {
            display: 'flex',
        },
        '& img': {
            height: 50,
            display: 'block',
            margin: '40px auto 56px',
        },
        '& h3': {
            fontSize: 20,
            marginBottom: theme.spacing(4),
            letterSpacing: '0.08em',
            lineHeight: '1.4em',
        },
        '& p': {
            marginBottom: theme.spacing(2)
        },
    },
    serviceItemText2: {
        marginBottom: `${theme.spacing(4)}px !important`,
        [theme.breakpoints.down('md')]: {
            marginBottom: `${theme.spacing(3)}px !important`,
        },
    },
    serviceItemBorder: {
        border: '1px solid #eee',
        borderRadius: 8,
        padding: theme.spacing(4),
        height: '94%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: 'initial',
        },
    },
    serviceItemFee: {
        '& p': {
            marginBottom: '0 !important',
        },
        '& .MuiGrid-root': {
            marginBottom: '0 !important',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: `100%`,
        },
    },
    serviceItemFeeTitle: {
        borderBottom: '1px solid #ddd',
        width: '29px !important',
        lineHeight: '1.8em !important',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0 !important',
        },
    },
    serviceFeeContent: {
        '& p': {
            lineHeight: '1.8em !important'
        }
    }
}));

export default useStyles;