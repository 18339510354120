import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from '../../styles/commonStyle'; 

const TitleWithShortLine = (props) => {
    const classes = useStyles();
    return (
        <Typography variant="body2" className={classes.title}>
            {props.titleText}
            <span className={classes.titleLine}></span>
        </Typography>
    )
}

export default TitleWithShortLine;