import React from 'react';
import { CardActions, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import useStyles from '../../styles/teamStyle';

const ExpandableIconButton = props => {
    const classes = useStyles();
    const { expanded, onClick } = props;

    return(
        <CardActions disableSpacing>
            <IconButton
                className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                })}
                onClick={onClick}
                aria-expanded={expanded}
                aria-label="show more"
                >
                <ExpandMoreIcon />
            </IconButton>
        </CardActions>
    )
}

export default ExpandableIconButton;