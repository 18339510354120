import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../../styles/serviceStyle';

const ServiceItem = (props) => {
    const classes = useStyles();
    const serviceData = props.serviceFeeObjArray

    return (
        (serviceData ? 
            serviceData.map((serviceItem, index) => {
                return(
                    <Grid item key={index} xl={3} lg={4} md={6} sm={12} xs={12} className={classes.serviceItem}>
                        <div className={classes.serviceItemBorder}>
                            <img src={serviceItem.img} alt={serviceItem.name} />
                            <Typography variant="h3" color="textSecondary">{serviceItem.name}</Typography>
                            <Typography variant="body1" className={classes.serviceItemText}>{serviceItem.description}</Typography>
                            <Typography variant="body1" className={classes.serviceItemText2}>{serviceItem.description2}</Typography>
                            <Grid container spacing={1} alignItems="baseline" className={classes.serviceItemFee}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="body2" color="textSecondary" className={classes.serviceItemFeeTitle}>
                                        收費
                                    </Typography>    
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.serviceFeeContent}>
                                    {serviceItem.fee.map((fee, index)=>{
                                        return(
                                            <Typography variant="body2" key={index} color="textSecondary">
                                                {fee}
                                            </Typography>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                            </div>
                    </Grid>
                )
            })
            : 
            ''
        )
    )
}

export default ServiceItem;