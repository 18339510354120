import React, { Fragment } from 'react';
import { SupervisorAccountOutlined, SupervisedUserCircleOutlined } from '@material-ui/icons';

export const drawerList = [
    {
        link: '/admin/psychologists',
        icon: <Fragment><SupervisedUserCircleOutlined /></Fragment>,
        text: '心理師'
    },
    {
        link: '/admin/supervisors',
        icon: <Fragment><SupervisorAccountOutlined /></Fragment>,
        text: '督導'
    },

]