import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import dotLogo from '../../assets/images/dot-logo.svg';
import wholeTextLogo from '../../assets/images/whole-textlogo.svg';
import keyVisualBG from '../../assets/images/pc-bg.png';
import useStyles from '../../styles/keyVisualStyle';

const KeyVisual = () => {
    const classes = useStyles();

    return (
        <div className={classes.keyVisualWrapper}>
            <Grid container className={classes.keyVisual} spacing={3} alignItems="center">
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <img src={dotLogo} alt="Logo" className={classes.dotLogoImg} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="flex-end">
                        <Grid item className={classes.textLogo}>
                            <img src={wholeTextLogo} alt="拾月拾日" />
                        </Grid>  
                        <Grid item className={classes.slogan}>
                            <Typography variant="body2" color="textSecondary">心理治療是一段取捨而拾獲的旅程，從月缺到月圓的日常。</Typography>
                        </Grid>  
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.keyVisualBG}>
                <img src={keyVisualBG} alt="background" />
            </div>
        </div>
    )
}

export default KeyVisual;