import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './Auth';
import PrivateLayout from './admin/layouts/PrivateLayout';

const PrivateRoute = ({ component: RouteComponent, ...rest}) => {
    const { validAdmin } = useContext(AuthContext);

    return (
        <Route 
            {...rest}
            render={ routeProps => 
                !!validAdmin ?
                <PrivateLayout><RouteComponent {...routeProps} /></PrivateLayout>
                :
                <Redirect to={'/admin/login'} />
            }
        />
    )
}

export default PrivateRoute;