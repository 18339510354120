import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const FullWidthLoading = () => {
    return (
        <Box height="100vh" width="100vw" alignItems="center" justifyContent="center" display="flex">
            <CircularProgress />
        </Box>
    )
}

export default FullWidthLoading;