export const locationAndUrl = [
  {
    location: "taichung",
    url:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3640.5095897049478!2d120.674738!3d24.1538549!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34693d257705abd9%3A0xbc0cff67ab811565!2z5ou-5pyI5ou-5pel5b-D55CG5rK755mC5omA!5e0!3m2!1sen!2stw!4v1590907698171!5m2!1sen!2stw",
  },
  {
    location: "fengyuan",
    url:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4192.788260035853!2d120.7124077014874!3d24.2625409361865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34691a0b2bca9d9d%3A0x9c1028e3cd6892b7!2sNo.%20199%2C%20Huludun%203rd%20St%2C%20Fengyuan%20District%2C%20Taichung%20City%2C%20Taiwan%20420!5e0!3m2!1sen!2suk!4v1631016180355!5m2!1sen!2suk",
  },
];

export const locationInfo = [
  { name: "台中", address: "台中市北區文武街6號", phone: "0919-591010" },
  { name: "豐原", address: "台中市豐原區葫蘆墩三街199號", phone: "0919-591010" },
];
