import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const Name = props => {

    const { txt } = props;

    return (
        <Grid container alignItems="center" direction="row" spacing={1}>
            <Grid item xs={12} sm={12} md>
                <Typography variant="caption" color="secondary">
                    姓名｜
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md>
                <Typography variant="h6" color="textPrimary">
                    {txt}
                </Typography>
            </Grid>
        </Grid>
    )
} 

export default Name;