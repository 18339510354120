import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { AuthContext } from '../../../Auth';

const UsernameText = () => {

    const { currentUser } = useContext(AuthContext);

    return (
        <Typography 
            variant="caption"
        >
            Hello, {currentUser?.displayName}!
        </Typography>
    )
}

export default UsernameText;