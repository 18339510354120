import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(4),
        fontSize: 20,
    },
    titleLine: {
        display: 'block',
        width: 20,
        height: 1,
        marginTop: theme.spacing(2),
        background: '#000',
    },
    pageTitle: {
        height: 160,
        position: 'relative',
        top: 50,
        overflow: 'hidden',
        marginBottom: theme.spacing(12),
    },
    pageTitleText: {
        position: 'relative',
        zIndex: 4,
        '& h5': {
            letterSpacing: '0.2em'
        },
        '& span': {
            display: 'block',
            margin: '10px auto 0',
            width: 30,
            height: 1,
            background: '#000'
        }
    },
    pageTitleImg: {
        position: 'absolute',
        width: '100%',
        top: 0,
        zIndex: 1,
        '& img': {
            width: '100%',
        }
    }
}));

export default useStyles;