import React, { useState } from 'react';
import useStyles from '../../styles/teamStyle';
import { Card, Grid, CardContent, Collapse, Divider } from '@material-ui/core';
import MemberExperienceTitle from '../common/MemberExperienceTitle';
import MemberExperienceText from '../common/MemberExperienceText';
import { MemberNameText, MemberTitleText } from '../common/MemberTexts';
import ExpandableIconButton from '../common/ExpandableIconButton';

const SupervisorItem = props => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { data } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    (data ? 
      data.map((person, index) => {
        return (
          <Grid key={index} item lg={4} md={6} sm={6} xs={12} className={`${classes.teamMemberWrap} ${classes.supervisorWrap}`}>
            <Card className={classes.teamMemberItem} variant="outlined">
            <img className={classes.teamMemberImg} src={person.img} alt={person.name} />
            <CardContent>
              <Grid container spacing={3} alignItems="center" justifyContent="center">
                  <Grid item md={5} sm={12} xs={6}>
                    <MemberNameText name={person.name} />
                  </Grid>
                  <Grid item md={7} sm={12} xs={6}>
                    <MemberTitleText title={person.title} />
                  </Grid>
                  <Grid item sm={12} className={classes.supervisorExperienceHighlight}>
                    {person.current_experience.length > 0 && <MemberExperienceTitle title="現任" />}
                    {person.current_experience.map((info, index) => {
                      return <MemberExperienceText index={index} info={info} />
                    })}
                    {person.education_experience.length > 0 && <MemberExperienceTitle title="學歷" />}
                    {person.education_experience.map((info, index) => {
                      return <MemberExperienceText index={index} info={info} />
                    })}
                  </Grid>
              </Grid>
            </CardContent>
            
            <ExpandableIconButton 
              expanded={expanded} 
              onClick={handleExpandClick} 
            />
            
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent  className={classes.memberExperiences}>
                {person.other_experience.length > 0 &&  <MemberExperienceTitle title="經歷" /> }
                {person.other_experience.map((info, index) => {
                  return <MemberExperienceText index={index} info={info} />
                })}
                {person.certification.length > 0 && <><Divider /><MemberExperienceTitle title="認證" /> </>}
                {person.certification.map((info, index) => {
                  return <MemberExperienceText index={index} info={info} />
                })}
                {person.qualification.length > 0 && <><Divider /><MemberExperienceTitle title="資格" /></>}
                {person.qualification.map((info, index) => {
                  return <MemberExperienceText index={index} info={info} />
                })}
              </CardContent>
            </Collapse>
          </Card>
          </Grid> 
        )
      })
    :
       '' 
    )
  );
}

export default SupervisorItem;