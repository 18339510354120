import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    team: {
        '& .MuiChip-outlined': {
            borderColor: '#FFE451',
            margin: '3px 2px !important',
        },
        '& .MuiCardContent-root': {
            padding: theme.spacing(3),
        },
    },
    teamMembers: {
        padding: '5% 8%',
        margin: '0 !important',
        maxWidth: '100%'
    },
    teamMemberWrap: {
        '&>.MuiCard-root':{
            height: '100% !important',
            '&>.MuiCardContent-root': {
                padding: theme.spacing(3),
                height: '200px !important',
                paddingBottom: '0 !important',
                [theme.breakpoints.down('lg')]: {
                    height: '200px !important',
                },
                [theme.breakpoints.down('md')]: {
                    height: '220px !important',
                },
                [theme.breakpoints.down('sm')]: {
                    height: '280px !important',
                },
                [theme.breakpoints.down('xs')]: {
                    height: 'initial !important',
                },
            },
        }, 
        '& .MuiTypography-colorTextPrimary': {
            marginBottom: '8px !important',
        },
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '24px !important',
        },
    },
    supervisorWrap: {
        '&>.MuiCard-root':{
            '&>.MuiCardContent-root': {
                padding: theme.spacing(3),
                height: '200px !important',
                paddingBottom: '0 !important',
                [theme.breakpoints.down('lg')]: {
                    height: '300px !important',
                },
                [theme.breakpoints.down('md')]: {
                    height: '300px !important',
                },
                [theme.breakpoints.down('sm')]: {
                    height: '350px !important',
                },
                [theme.breakpoints.down('xs')]: {
                    height: 'initial !important',
                },
            },
        },    
    },
    supervisorExperienceHighlight: {
        padding: '12px !important',
        [theme.breakpoints.down('xs')]: {
           width: '100% !important',
        },
    },
    teamMemberName: {
        fontSize: 24,
        paddingBottom: theme.spacing(2),
        borderBottom: '2px solid #FFE451',
        display: 'inline-block',
        paddingTop: theme.spacing(1),
    },
    teamMemberTitle: {
        fontSize: 14,
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'right',
        },
    },
    teamMemberKeyword: {
        marginLeft: -14,
        paddingRight: '0 !important',
    },
    teamMemberImg: {
        display: 'block',
        width: '100%'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }, 
    memberExperiences: {
        '& hr': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }
    },
    memberExperienceTitle: {
        marginBottom: theme.spacing(1),
    } 

}));

export default useStyles;