import React from 'react';
import { Box, Button } from '@material-ui/core';
import { ArrowBackIosRounded } from '@material-ui/icons';

const BackToPreviousPageButton = props => {

    const { link, txt } = props;

    return (
        <Box mb={2}>
            <Button 
                href={link} 
                color="secondary"
                startIcon={<ArrowBackIosRounded />}>
                {txt}
            </Button>
        </Box>
    )
}

export default BackToPreviousPageButton;