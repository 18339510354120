import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  slideshow: {
    overflow: "hidden",
    width: "100%",
    minHeight: "80vh !important",
    background: "#fff",
    position: "relative",
    zIndex: 2,
    "& .slick-slider": {
      // height: '100% !important',
    },
    "& .slick-track": {
      // height: '90vh !important',
      // minHeight: '700px !important',
      overflow: "hidden",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "stretch",
    },
    "& .slick-slide": {
      height: "initial !important",
    },
    "& .slick-dots": {
      bottom: "5%",
      [theme.breakpoints.down("md")]: {
        // bottom: '20%'
      },
    },
    "& .slick-dots li": {
      width: "8px !important",
      height: "8px !important",
      margin: "0 10px",
    },
    "& .slick-dots li button": {
      background: "rgba(255,255,255,0.5)",
      width: "8px !important",
      height: "8px !important",
    },
    "& .slick-dots li.slick-active button": {
      background: "#fff",
    },
    "& .slick-dots li button:before": {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {
      // height: '90vh !important',
      "& .slick-dots": {
        // bottom: '10%',
      },
    },
    [theme.breakpoints.down("md")]: {
      // height: '85vh !important',
    },
    [theme.breakpoints.down("xs")]: {
      // height: '800px !important',
      height: "initial !important",
    },
  },
  homeServiceSection: {
    padding: "8%",
    maxWidth: "100vw !important",
    position: "relative",
    background: "#fff",
    zIndex: 2,
    "& h1": {
      fontSize: 20,
      marginBottom: theme.spacing(3),
    },
    "& h2": {
      fontSize: 20,
      marginBottom: theme.spacing(4),
      lineHeight: "1.5em",
    },
    "& h3": {
      fontSize: 20,
      color: "#ecc906 !important",
      marginBottom: theme.spacing(8),
    },
    "& .MuiGrid-item": {
      "& h3": {
        color: "#FFE451",
      },
      "& div": {
        border: 0,
      },
      "& p": {
        position: "relative",
      },
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100vw !important",
      margin: "0 !important",
      "& .MuiGrid-item": {
        marginBottom: theme.spacing(3),
      },
    },
  },
  homeServiceItem: {
    "& img": {
      height: "50px",
      display: "block",
      margin: "0 auto 80px",
    },
  },
  serviceSectionBottomline: {
    width: "84%",
    height: 1,
    margin: "0 auto",
    background: "#aaa",
  },
  pageTitle: {
    height: 160,
    position: "relative",
    top: 50,
    overflow: "hidden",
    marginBottom: theme.spacing(3),
  },
  pageTitleText: {
    position: "relative",
    zIndex: 4,
    "& h3": {
      marginBottom: 10,
    },
    "& span": {
      display: "block",
      margin: "16px auto 0",
      width: 20,
      height: 1,
      background: "#000",
    },
  },
  openingDay: {
    padding: "8%",
    position: "relative",
    marginTop: -2,
    zIndex: 2,
    background: "#fff",
    "& .slick-slider": {
      "& .MuiSvgIcon-root": {
        fill: "#888",
        fontSize: "2.5rem",
      },
      "& .slick-prev": {
        left: -65,
        [theme.breakpoints.down("xs")]: {
          left: -60,
        },
        [theme.breakpoints.down("xs")]: {
          left: -50,
        },
      },
      "& .slick-next": {
        right: -30,
      },
      "& .slick-prev:before": {
        display: "none,",
      },
      "& .slick-next:before": {
        display: "none,",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& .slick-slider": {
        width: "80%",
        margin: "0 auto",
      },
    },
  },
  openingDayData: {
    position: "relative",
    right: "5%",
    [theme.breakpoints.down("sm")]: {
      right: 0,
    },
  },
  openingDayMonth: {
    marginBottom: "24px !important",
  },
  openingDayIcon: {
    position: "relative",
    left: "-5px !important",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto 10px",
      display: "block",
      left: "0 !important",
    },
  },
  dmImg: {
    width: "100%",
  },
  selectLocation: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: 240,
    "& .MuiSelect-select.MuiSelect-select": {
      overflow: "hidden !important",
    },
    "& .MuiInput-underline:before": {
      border: 0,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: 0,
    },
    [theme.breakpoints.down("sm")]: {
      height: 100,
      justifyContent: "center",
    },
  },
  map: {
    zIndex: 3,
    border: 0,
    background: "#fff",
    paddingBottom: theme.spacing(4),
    position: "relative",
    "& .MuiTypography-body2": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiBox-root": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "& .MuiTypography-body2": {
          marginBottom: "8px",
        },
      },
    },
  },
}));

export default useStyles;
