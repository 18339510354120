import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from '../../styles/loadingIndicatorStyle';

const LoadingIndicator = () => {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <CircularProgress />
        </div>
    )
}

export default LoadingIndicator;