import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( () => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '300px'
    }
}));

export default useStyles;