import { Box, Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { uploadBlob } from "../../../config/uploadingImgToFirebase";
import { addPsychologist, editPsychologist } from "../../../helpers/firestoreHelper";
import { professionKeywords } from "../../constants/dataForFormColumnRender";
import { FormSectionTitle, TipOnly } from "../common/FormTexts";
import AvatorUploader from "../form/AvatorUploader";
import Controls from "../form/Controls";
import { Form, useForm } from "../form/Form";
import _ from "lodash";

const PsychologistForm = (props) => {
  const { initialValues } = props;
  const [formValues, setFormValues] = useState(initialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let requiredText = "This field is required";

    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : requiredText;
    }
    if ("title" in fieldValues) {
      temp.title = fieldValues.title ? "" : requiredText;
    }
    if ("certification_number" in fieldValues) {
      temp.certification_number = fieldValues.certification_number ? "" : requiredText;
    }
    if ("working_experience" in fieldValues) {
      temp.working_experience = fieldValues.working_experience ? "" : requiredText;
    }
    if ("education_experience" in fieldValues) {
      temp.education_experience = fieldValues.education_experience ? "" : requiredText;
    }
    if ("avatar" in fieldValues) {
      temp.avatar = fieldValues.avatar.src !== defaultImage ? "" : requiredText;
    }

    setErrors({ ...temp });

    let validationResult = !Object.values(temp).includes(requiredText);
    return validationResult;
  };

  const {
    values,
    defaultImage,
    setIsFileSizeError,
    isFileExtensionError,
    setIsFileExtensionError,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(formValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitting(true);
      (async () => {
        const avatarUrl = values.avatar.file ? await uploadBlob(values.avatar.file) : values.avatar.src;

        if (values.id) {
          await editPsychologist({ ...values, avatar_url: avatarUrl });
        } else {
          await addPsychologist({ ...values, avatar_url: avatarUrl });
        }

        if (avatarUrl) {
          window.location.assign('/admin/psychologists');
          setIsSubmitting(false);
        }
      })();
    }
  };

  useEffect(() => {
    setFormValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormSectionTitle title="基本資料" />
      <Grid container>
        <Box display="flex" flexDirection="row" alignItems="start">
          <AvatorUploader
            value={values.avatar}
            name="avatar"
            setIsFileSizeError={setIsFileSizeError}
            isFileExtensionError={isFileExtensionError}
            setIsFileExtensionError={setIsFileExtensionError}
            handleInputChange={handleInputChange}
            error={errors.avatar}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} className="mb_16">
              <Controls.Input
                name="name"
                value={values.name}
                label="姓名"
                onChange={handleInputChange}
                error={errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="mb_16">
              <Controls.Input
                name="certification_number"
                value={values.certification_number}
                label="證號"
                onChange={handleInputChange}
                error={errors.certification_number}
              />
              <TipOnly tip="例：心理字第000000號、HCPC證號AB12345" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="mb_16">
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={5} lg={3}>
                  <Controls.Input
                    name="title"
                    value={values.title}
                    label="職稱"
                    onChange={handleInputChange}
                    error={errors.title}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={5} lg={3}>
                  <Controls.Input
                    name="title_second"
                    value={values.title_second}
                    label="次職稱"
                    onChange={handleInputChange}
                    error={errors.title_second}
                  />
                </Grid>
              </Grid>
              <TipOnly tip="建議每個職稱最多6個字" />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Divider />
      <FormSectionTitle
        title={`專業關鍵字`}
        tip="使用者經驗小提醒：建議選擇至多八個，避免資訊過於複雜，以提供使用者清楚取得更精準的資訊"
      />
      <Grid container spacing={1}>
        {professionKeywords.map((o, i) => {
          const checkedStatus = _.some(values.keywords, o); // lodash: https://lodash.com/docs/4.17.15#some
          return (
            <Grid item key={i}>
              <Controls.Checkbox
                name={o.name}
                value={o.value}
                checked={checkedStatus}
                label={o.label}
                onChange={handleInputChange}
              />
            </Grid>
          );
        })}
      </Grid>
      <Divider />
      <FormSectionTitle
        title="經歷"
        tip="編輯提醒：請使用半形逗號 ‘, ’ 將各經歷分隔，如：長照服務醫事人員, 身心障礙者職業輔導評量員"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className="mb_16">
          <Controls.Input
            name="working_experience"
            value={values.working_experience}
            label="工作"
            onChange={handleInputChange}
            multiline
            fullWidth
            rows={4}
            error={errors.working_experience}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="mb_16">
          <Controls.Input
            name="education_experience"
            value={values.education_experience}
            label="學歷"
            onChange={handleInputChange}
            multiline
            fullWidth
            rows={4}
            error={errors.education_experience}
          />
        </Grid>
      </Grid>
      <Divider />
      <FormSectionTitle
        title="資格認證"
        tip="編輯提醒：請使用半形逗號 ‘, ’ 將各經歷分隔，如：長照服務醫事人員, 身心障礙者職業輔導評量員"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb_16">
          <Controls.Input
            name="certification"
            value={values.certification}
            label="資格證書"
            onChange={handleInputChange}
            multiline
            fullWidth
            rows={4}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1}>
        <Grid item className="mb_16">
          <Controls.Button type="submit" text={!isSubmitting ? "Save" : "Saving..."} variant="contained" disabled={isSubmitting} />
        </Grid>
        <Grid item className="mb_16">
          <Controls.Button text="Reset" color="secondary" onClick={resetForm} />
        </Grid>
        <Grid item className="mb_16">
          <Controls.Button text="Back" color="secondary" href="/admin/psychologists" />
        </Grid>
      </Grid>
    </Form>
  );
};

export default PsychologistForm;
