import React from 'react';
import useStyles from '../../styles/drawerStyle';
import { IconButton } from '@material-ui/core';
import { ExitToAppOutlined, MenuRounded, ChevronLeftRounded } from '@material-ui/icons';
import logo from '../../../assets/images/logo.svg';
import app from '../../../config/base';

export const LogOutBtn = () => {
  return (
    <IconButton 
      color="inherit" 
      onClick={() => app.auth().signOut()}
    >
      <ExitToAppOutlined color="action" />
    </IconButton>
  )
}

export const BurgerBtn = props => {
  const classes = useStyles();
  const { onClick } = props;

  return (
    <IconButton
        aria-label="open drawer"
        onClick={onClick}
        edge="start"
        className={classes.burgerBtn}
      >
        <MenuRounded />
      </IconButton>
  )
}

export const BackToWebsiteBtn = () => {
  const classes = useStyles();

  return (
    <a 
      href={process.env.REACT_APP_PSY_WEBSITE} 
      target="_blank" 
      className={classes.appBarLogo}
      rel="noopener noreferrer"
    >
        <ChevronLeftRounded color="action" /> 
        <img src={logo} alt="logo" />
    </a>
  )
}