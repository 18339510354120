import React, { useState } from "react";
import PCHeader from "../components/common/PCHeader";
import Footer from "../components/common/Footer";
import PageTitle from "../components/common/PageTitle";
import SpaceItem from "../components/space/spaceItem";
import spaceImg from "../assets/images/space1.jpg";
import friendlySpaceImg from "../assets/images/friendlySpace.jpg";
import friendlySpaceImg2 from "../assets/images/friendlySpace2.jpg";
import spaceImg3 from "../assets/images/space3.jpg";
import childSpaceImg from "../assets/images/childrenspace.jpg";
import childSpaceImg2 from "../assets/images/childrenspace2.jpg";
import talkingSpaceImg from "../assets/images/talkingroom.jpg";
import spaceImg4 from "../assets/images/space5.jpg";
import { Box, Chip, Typography } from "@material-ui/core";
import { PinDropOutlined } from "@material-ui/icons";

const Space = () => {
  const [value, setValue] = useState("taichung");

  const handleChange = (location) => {
    setValue(location);
  };
  return (
    <div>
      <PCHeader />
      <PageTitle pageTitle="環境介紹" />
      <Box mb={4} ml="8%" mr="8%" display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body2" className="pr-2 flex">
          <PinDropOutlined fontSize="small" />
          選擇適合你的分所
        </Typography>
        <Box display="flex" gridGap={10}>
          <Chip
            label="北區"
            disabled={value === "taichung"}
            onClick={() => handleChange("taichung")}
          />
          <Chip
            label="豐原"
            disabled={value === "fengyuan"}
            onClick={() => handleChange("fengyuan")}
          />
        </Box>
      </Box>
      {value === "taichung" ? (
        <>
          <SpaceItem
            flexDirection="row"
            imgLink={spaceImg4}
            imgAlt="等候空間"
            imgLink2={spaceImg}
            imgAlt2="等候空間2"
            spaceTitle="等候空間"
            spaceDescription="在這裡等候，是自在的。親友的等候時光是我們重視的環節，你可以在這裡放鬆休息，或者帶杯咖啡，翻閱一本書。"
          />
          <SpaceItem
            flexDirection="row-reverse"
            imgLink={friendlySpaceImg2}
            imgAlt="無障礙會談室"
            imgLink2={friendlySpaceImg}
            imgAlt2="無障礙會談室"
            spaceTitle="無障礙會談室"
            spaceDescription="位於一樓的會談室，我們在空間上降低了行動限制，讓輪椅可以進入，使行動不便的人及長者在此能獲得心理照顧的服務。"
          />
          <SpaceItem
            flexDirection="row"
            imgLink={spaceImg3}
            imgAlt="治療室"
            imgLink2={talkingSpaceImg}
            imgAlt2="治療室"
            spaceTitle="治療室"
            spaceDescription="我們重視談話的氛圍，盡可能地降低干擾，讓你能自在地訴說。"
          />

          <SpaceItem
            flexDirection="row-reverse"
            imgLink={childSpaceImg}
            imgAlt="遊戲治療室"
            imgLink2={childSpaceImg2}
            imgAlt2="遊戲治療室2"
            spaceTitle="遊戲治療室"
            spaceDescription="我們重視安全及自在的遊戲環境，讓你的孩子在安心及樂趣的氛圍下獲得幫助。"
          />
        </>
      ) : (
        <>
          {" "}
          <SpaceItem
            flexDirection="row"
            imgLink={spaceImg4}
            imgAlt="等候空間"
            imgLink2={spaceImg}
            imgAlt2="等候空間2"
            spaceTitle="等候空間"
            spaceDescription="在這裡等候，是自在的。親友的等候時光是我們重視的環節，你可以在這裡放鬆休息，或者帶杯咖啡，翻閱一本書。"
          />
          <SpaceItem
            flexDirection="row-reverse"
            imgLink={friendlySpaceImg2}
            imgAlt="無障礙會談室"
            imgLink2={friendlySpaceImg}
            imgAlt2="無障礙會談室"
            spaceTitle="無障礙會談室"
            spaceDescription="位於一樓的會談室，我們在空間上降低了行動限制，讓輪椅可以進入，使行動不便的人及長者在此能獲得心理照顧的服務。"
          />
          <SpaceItem
            flexDirection="row"
            imgLink={spaceImg3}
            imgAlt="治療室"
            imgLink2={talkingSpaceImg}
            imgAlt2="治療室"
            spaceTitle="治療室"
            spaceDescription="我們重視談話的氛圍，盡可能地降低干擾，讓你能自在地訴說。"
          />
          <SpaceItem
            flexDirection="row-reverse"
            imgLink={childSpaceImg}
            imgAlt="遊戲治療室"
            imgLink2={childSpaceImg2}
            imgAlt2="遊戲治療室2"
            spaceTitle="遊戲治療室"
            spaceDescription="我們重視安全及自在的遊戲環境，讓你的孩子在安心及樂趣的氛圍下獲得幫助。"
          />
        </>
      )}
      <Footer />
    </div>
  );
};

export default Space;
