export const professionKeywords = [
    { name: 'stress_adjustment', label: '壓力調適' },
    { name: 'emotion_problem', label: '情緒困擾' },
    { name: 'romantic_relationship', label: '感情關係' },
    { name: 'intimate_relationship', label: '親密關係' },
    { name: 'couple_counselling', label: '伴侶諮商' },
    { name: 'gender_diversity', label: '多元性別' },
    { name: 'marriage', label: '婚姻關係' },
    { name: 'work_issue', label: '職場議題' },
    { name: 'career_design', label: '職涯設計' },
    { name: 'english_counselling', label: '英語諮商' },
    { name: 'brain_assessment', label: '大腦評估' },
    { name: 'brain_rehabilitation', label: '大腦復健' },
    { name: 'parental_education', label: '親職教養' },
    { name: 'parenting_strategy', label: '親職策略' },
    { name: 'early_intervention', label: '幼兒早療' },
    { name: 'parenting', label: '育兒教養' },
    { name: 'children_physical_and_mental_development', label: '兒童身心發展' },
    { name: 'children_play_therapy', label: '兒童遊戲治療' },
    { name: 'children_emotional_disturbance', label: '兒童情緒/行為困擾' },
    { name: 'children_youth_development', label: '兒童青少年身心發展' },
    { name: 'parent-child-relationship', label: '親子關係' },
    { name: 'family_interaction', label: '家庭互動' },
    { name: 'pregnant_emotion', label: '孕產情緒' },
    { name: 'postpartum_depression', label: '產後憂鬱' },
    { name: 'pregnant_adjustment', label: '孕期調適' },
    { name: 'neuro_feedback', label: '神經回饋' },
    { name: 'physic_feedback', label: '生理回饋' },
    { name: 'self_exploration', label: '自我探索' },
    { name: 'self_growth', label: '自我成長' },
    { name: 'brain_injury', label: '成人腦傷' },
    { name: 'adult_elder_psychological_assessment', label: '成人及老人心理評估' },
    { name: 'elder_care', label: '高齡心理照護' },
    { name: 'dementia', label: '失智照護' },
    { name: 'long-term-care', label: '長照臨心居家復能' },
    { name: 'neuropsychology_rehabilitation', label: '神經心理復健' },
    { name: 'disabled_career_development', label: '身心障礙職涯輔導' },
    { name: 'disabled_psychology_rehabilitation', label: '身心障礙者心理復健' },
    { name: 'life_design', label: '生命設計' },
    { name: 'sadness', label: '失落悲傷' },
    { name: 'down_mood', label: '低落情緒' },
    { name: 'stress_relieve_sleep_improvement', label: '紓壓好眠' },
    { name: 'sleep_disorder', label: '睡眠困擾' },
    { name: 'interpersonal_exploration', label: '人際探索' },
    { name: 'asperger_syndrome', label: '自閉症亞斯伯格症' },
    { name: 'brain_psychological_assessment', label: '腦心智功能評估與治療' },
    { name: 'positive_psychocounselling', label: '正向心理治療' },
    { name: 'health_psychology', label: '健康心理學' },
    { name: 'career_development', label: '生涯發展' },
    { name: 'career_exporation', label: '生涯探索' },
    { name: 'adhd_autism', label: '過動自閉' },
    { name: 'mania_depression', label: '憂鬱躁鬱' },
    { name: 'anxiety', label: '焦慮疾患' },
    { name: 'anxiety_depression', label: '憂鬱焦慮' },
]