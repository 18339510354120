import React from 'react';
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core';

const CustomedCheckbox = props => {

    const { label, name, value, checked, onChange } = props;

    const convertToDefEventPara = (name, label, checked) => {
        return {
            target: {
            name, label, checked
        }}
    }

    return (
        <FormControl>
            <FormControlLabel
                control={
                    <Checkbox 
                        name={name}
                        checked={checked}
                        label={value}
                        onChange={ () => onChange(convertToDefEventPara(name, label, checked), 'checkbox')}
                    />
                }
                label={label}
            />
        </FormControl>
    )
}

export default CustomedCheckbox;