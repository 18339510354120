import React from "react";
import { Grid } from "@material-ui/core";
import TitleWithShortLine from "../common/TitleWithShortLine";
import useStyles from "../../styles/homeStyle";
import dm1 from "../../assets/images/dm1.jpg";
import dm2 from "../../assets/images/dm2.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "block" }} onClick={onClick}>
      <ChevronRightIcon />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "block" }} onClick={onClick}>
      <ChevronLeftIcon />
    </div>
  );
}

const DMSection = () => {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    fade: true,
  };

  return (
    <Grid container className={classes.openingDay}>
      <Grid item xl={7} lg={6} md={5} sm={12} xs={12}>
        <TitleWithShortLine titleText="介紹" />
        {/* <div className={classes.selectLocation}>
          <Chip label="北區" disabled onClick={handleClick} />
          <Chip label="豐原" onClick={handleClick} />
        </div> */}
      </Grid>
      <Grid item xl={5} lg={6} md={7} sm={12} xs={12} className={classes.openingDayData}>
        <Slider {...settings}>
          <Grid container>
            <Grid item xs={12}>
              <img src={dm1} alt="服務介紹" className={classes.dmImg} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <img src={dm2} alt="環境介紹" className={classes.dmImg} />
            </Grid>
          </Grid>
        </Slider>
      </Grid>
    </Grid>
  );
};

export default DMSection;
