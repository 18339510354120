import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    slideshowTextSection: {
        padding: '8%',
        marginBottom: theme.spacing(3),
        position: 'relative',
        '& .MuiGrid-item': {
            padding: theme.spacing(8),
        },
        '& .MuiButton-text': {
            marginTop: theme.spacing(4),
        },
        [theme.breakpoints.down('md')]: {
            // padding: '20%',
            marginBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10%',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '10%',
        },
    },
    slideshowTag: {
        marginBottom: theme.spacing(4),
        fontSize: 20,
    },
    line: {
        display: 'block',
        width: 20,
        height: 1,
        marginTop: theme.spacing(1),
        background: '#000',
    },
    slideshowTitle: {
        marginBottom: theme.spacing(4),
        fontSize: 30,
        position: 'relative',
        zIndex: 2,
    },
    slideshowText: {
        marginBottom: theme.spacing(1),
        fontSize: 20,
        position: 'relative',
        zIndex: 2,
        lineHeight: 1.6,
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
    },
    slideshowImg: {
        position: 'absolute',
        width: '100%',
        top: 0,
        left: 0,
        padding: '0 !important',
        minHeight: '100%',
        zIndex: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& img': {
            minWidth: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0'
        },
        [theme.breakpoints.down('md')]: {
            '& img': {
                height: '100%',
                width: 'auto',
                minWidth: '100%',
                position: 'absolute',
            },
        }
    },
    slideshowImgWrap: {
        position: 'relative',
        width: '100%',
        height:'0',
        paddingTop: '68%',
        [theme.breakpoints.down('md')]: {
            paddingTop: '70% !important'
        },
        [theme.breakpoints.down('sm')]: {
            // paddingTop: '160% !important',
            height: 'inherit !important',
        }
    },
    whiteBgSection: {
        background: '#fff',
        position: 'relative',
        zIndex: 3,
    }
}));

export default useStyles;