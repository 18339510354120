import React from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import { PersonAddOutlined, CheckRounded } from '@material-ui/icons';

export const AddMemberButton = props => {

    const { link, txt } = props;

    return (
        <Button 
            href={link} 
            variant="contained" 
            color="secondary"
            startIcon={<PersonAddOutlined />}>
            {txt}
        </Button>
    )
}

export const AddMemberIconButton = props => {

    const { link } = props;

    return (
        <IconButton 
            href={link} 
            variant="contained" 
            className="fixed-add-btn"
            color="secondary" >
            <PersonAddOutlined />
        </IconButton>
    )
}

export const SaveOrderButton = props => {

    const { txt, onClick, disabled } = props;

    return (
        <Box mr={1} display="inline-block">
            <Button 
                onClick={onClick}
                disabled={disabled}
                variant="contained" 
                color="secondary"
                startIcon={<CheckRounded />}>
                {txt}
            </Button>
        </Box>
    )
}

export const SaveOrderIconButton = props => {

    const { onClick, disabled } = props;

    return (
        <IconButton 
            onClick={onClick}
            variant="contained" 
            className="fixed-save-order-btn"
            disabled={disabled}
            color="secondary" >
            <CheckRounded />
        </IconButton>
    )
}