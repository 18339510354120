import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./PrivateRoute";
import theme from "./theme/muiTheme";
import Home from "./containers/Home";
import Space from "./containers/Space";
import About from "./containers/About";
import Services from "./containers/Services";
// import Booking from "./containers/Booking";
import Team from "./containers/Team";
import Login from "./admin/containers/Login";
import Psychologists from "./admin/containers/Psychologists";
import Supervisors from "./admin/containers/Supervisors";
import PsychologistEditPage from "./admin/containers/PsychologistEditPage";
import PsychologistAddPage from "./admin/containers/PsychologistAddPage";
import SupervisorEditPage from "./admin/containers/SupervisorEditPage";
import SupervisorAddPage from "./admin/containers/SupervisorAddPage";
import ScrollIntoView from "./components/common/ScrollIntoView";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router>
            <ScrollIntoView>
              <Switch>
                <PrivateRoute
                  exact
                  path="/admin/supervisor_add"
                  component={SupervisorAddPage}
                />
                <PrivateRoute
                  exact
                  path="/admin/supervisor_edit/:id"
                  component={SupervisorEditPage}
                />
                <PrivateRoute
                  exact
                  path="/admin/supervisors"
                  component={Supervisors}
                />
                <PrivateRoute
                  exact
                  path="/admin/psychologist_add"
                  component={PsychologistAddPage}
                />
                <PrivateRoute
                  exact
                  path="/admin/psychologist_edit/:id"
                  component={PsychologistEditPage}
                />
                <PrivateRoute
                  exact
                  path="/admin/psychologists"
                  component={Psychologists}
                />
                <Route path="/booking" exact component={Home} />
                <Route path="/service" exact component={Services} />
                <Route path="/about" exact component={About} />
                <Route path="/space" exact component={Space} />
                <Route path="/team" exact component={Team} />
                <Route path="/home" exact component={Home} />
                <Route path="/admin/login" component={Login} />
                <Route path="/admin" component={Login} />
                <Route path="/" exact component={Home} />
              </Switch>
            </ScrollIntoView>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
