/* eslint-disable no-unused-vars */

import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Footer from "../components/common/Footer";
import PageTitle from "../components/common/PageTitle";
import PCHeader from "../components/common/PCHeader";
import SupervisorItem from "../components/team/SupervisorItem";
import TeamMemberItem from "../components/team/TeamMemberItem";
import app from "../config/base";
import useStyles from "../styles/teamStyle";

const Team = () => {
  const classes = useStyles();
  const [psychologists, setPsychologists] = useState([]);
  const [supervisiors, setSupervisiors] = useState([]);

  const fillMemberData = (doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      name: data["name"] || "",
      title: `${data["title"] || ""}${data["title_second"] ? `／${data.title_second}` : ""}`,
      img: data["avatar_url"],
      number: data["certification_number"],
      keyword: (data["keywords"] && data["keywords"].map((keyword) => keyword.label)) || [],
      education_experience: (data["education_experience"] && data["education_experience"].split(",")) || [],
      working_experience: (data["working_experience"] && data["working_experience"].split(",")) || [],
      certification: (data["certification"] && data["certification"].split(",")) || [],
      current_experience: (data["current_experience"] && data["current_experience"].split(",")) || [],
      other_experience: (data["other_experience"] && data["other_experience"].split(",")) || [],
      qualification: (data["qualification"] && data["qualification"].split(",")) || [],
    };
  };

  useEffect(() => {
    const loadMembers = async () => {
      const psychologistSnapshots = await app.firestore().collection("psychologists").orderBy("sequence", "asc").get();
      const psychologists = psychologistSnapshots.docs.map((doc) => fillMemberData(doc));
      setPsychologists(psychologists);

      const supervisiorSnapshots = await app.firestore().collection("supervisiors").orderBy("sequence", "asc").get();
      const supervisiors = supervisiorSnapshots.docs.map((doc) => fillMemberData(doc));
      setSupervisiors(supervisiors);
    };

    loadMembers();
  }, []);

  return (
    <div className={classes.team}>
      <PCHeader />
      <PageTitle pageTitle="團隊介紹" />
      <Grid container spacing={3} alignItems="stretch" className={classes.teamMembers}>
        <TeamMemberItem data={psychologists} />
      </Grid>
      <PageTitle pageTitle="督導/合作律師" />
      <Grid container spacing={3} alignItems="stretch" className={classes.teamMembers}>
        <SupervisorItem data={supervisiors} />
      </Grid>
      <Footer />
    </div>
  );
};

export default Team;
