import React from 'react';
import '../../admin.css';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import useStyles from '../../styles/drawerStyle';
import { LogOutBtn, BurgerBtn, BackToWebsiteBtn } from './ActionBtns';
import UsernameText from './UsernameText';

const StyledAppBar = props => {
  const classes = useStyles();
  const { menuIconOnClick } = props;

  return (
    <div className="flex">
      <AppBar 
        position="fixed"
        color="default"
        elevation={0}
        className={`flex ${classes.appBar}`}>
        <Toolbar>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center" flexDirection="row" width="fit-content">
                    <BurgerBtn
                    onClick={menuIconOnClick}
                    />
                    <BackToWebsiteBtn />
                </Box>
                <Box>
                    <UsernameText />
                    <LogOutBtn />
                </Box>
            </Box>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default StyledAppBar;