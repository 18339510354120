import React, { useState, useEffect } from 'react';
import app from './config/base';
import FullWidthLoading from './admin/components/common/FullWidthLoading';

export const AuthContext = React.createContext();

export const AuthProvider = ({children}) => {
    const [ currentUser, setCurrentUser ] = useState(null);
    const [ validAdmin, setValidAdmin ] = useState(null);
    const [ pending, setPending ] = useState(true);
    
    useEffect(() => {
        app.auth().onAuthStateChanged(async (user) => {
            setCurrentUser(user);

            const admins = [];
            const snapshots = await app.firestore().collection("admins").get();
            snapshots.forEach((snapshot) => admins.push(snapshot.data().email));
            setValidAdmin(admins.includes(user?.email));

            setPending(false);
          });
    }, [])

    if(pending){
        return <FullWidthLoading />
    }
    
    return (
        <AuthContext.Provider
            value={{currentUser, validAdmin}}
        >
            {children}
        </AuthContext.Provider>
    )
}