import defaultImage from "../../assets/images/default_image.svg";

export const psychologistsInitialValues = {
  id: "00",
  sequence: 0,
  avatar: { file: "", src: defaultImage },
  name: "",
  title: "",
  title_second: "",
  keywords: [],
  certification_number: "",
  working_experience: "",
  education_experience: "",
  certification: "",
};
export const psychologistsEmptyValues = {
  sequence: 0,
  avatar: { file: "", src: defaultImage },
  name: "",
  title: "",
  title_second: "",
  keywords: [],
  certification_number: "",
  working_experience: "",
  education_experience: "",
  certification: "",
};


export const supervisorsInitialValues = {
    id: "00",
    sequence: 0,
    avatar: { file: "", src: defaultImage },
    name: "",
    title: "",
    title_second: "",
    education_experience: "",
    current_experience: "",
    certification: "",
    other_experience: "",
    qualification: "",
  };
  export const supervisorsEmptyValues = {
    sequence: 0,
    avatar: { file: "", src: defaultImage },
    name: "",
    title: "",
    title_second: "",
    education_experience: "",
    current_experience: "",
    certification: "",
    other_experience: "",
    qualification: "",
  };
  