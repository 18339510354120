import React from 'react';
import useStyles from '../../styles/teamStyle';
import { Typography } from '@material-ui/core';

const MemberExperienceTitle = props => {
    const classes = useStyles();
    const { title } = props;

    return(
        <Typography variant="body2" color="textSecondary" className={classes.memberExperienceTitle}>
            {title}
        </Typography>
    )
}

export default MemberExperienceTitle;