import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import app from "../../config/base";
import BackToPreviousPageButton from "../components/common/BackToPreviousPageButton";
import PageTitleAndSubtitle from "../components/common/PageTitleAndSubtitle";
import SupervisorForm from "../components/member/SupervisorForm";
// import { useParams } from "react-router-dom";
import { supervisorsInitialValues } from "../constants/formInitialValues";

const SupervisorEditPage = () => {
  const { id } = useParams();
  const [values, setValues] = useState({});

  useEffect(() => {
    const loadPsychologist = async () => {
      const snapshot = await app.firestore().collection("supervisiors").doc(id).get();
      const psychologist = { ...snapshot.data(), id };
      setValues(psychologist);
      console.dir(psychologist, { depth: null });
    };
    loadPsychologist();
  }, [id]);

  return (
    <>
      <BackToPreviousPageButton link="/admin/supervisors" txt="回督導列表" />
      <PageTitleAndSubtitle title="更新督導" />
      <SupervisorForm
        initialValues={{ ...supervisorsInitialValues, ...values, avatar: { src: values["avatar_url"] } }}
      />
    </>
  );
};

export default SupervisorEditPage;
