import React from 'react';
import { Box, Typography } from '@material-ui/core';
import defaultImage from '../../../assets/images/default_image.svg';
import Controls from './Controls';

const AvatorUploader = props => {
    
    const { 
        error,
        name, 
        value, 
        isFileSizeError, 
        setIsFileSizeError,
        isFileExtensionError, 
        setIsFileExtensionError,
        handleInputChange
    } = props;
    
    let validExtension = ['jpg', 'jpeg', 'png'];
    
    const validateFileSizeAndFormat = e => {
        
        const files = e.target.files;
        
        setIsFileSizeError(false);
        setIsFileExtensionError(false);

        if (files && files[0]) {

            const src = URL.createObjectURL(files[0]);
            const size = files[0].size / 1024 / 1024;
            const name =  files[0].name; 
            const extension = name.split('.').pop();
            const isFileExtensionValid = validExtension.includes(extension);
            const isFileSizeValid = size <= 5;
            
            setIsFileSizeError(!isFileSizeValid);
            setIsFileExtensionError(!isFileExtensionValid);

            if (isFileSizeValid && isFileExtensionValid) {
                const file = {file: files[0], src: src};
                handleInputChange(e, 'avatar', file);
            } 

        } else {

            const file = { file: '', src: defaultImage}
            handleInputChange(e, 'avatar', file);

        }
        
    }

    return (
        <Box className="avatar-uploader-wrap" mr={2}>
            <Box>
                <Controls.Input
                    name={name}
                    type="file"
                    onChange={e => validateFileSizeAndFormat(e) }
                    error={error}
                    className="pointer"
                />
                <div className="avatar-image">
                    <img src={value.src} alt="avatar" />
                </div>
            </Box>
            <Box>
                <Typography variant="caption" color="secondary">最大上傳容量：5MB</Typography>
                <Typography variant="caption" color="secondary">支援格式：jpg, png</Typography>
                { isFileSizeError &&
                    <Typography variant="caption" color="error">請上傳小於5MB的圖片。</Typography>
                }
                { isFileExtensionError && 
                    <Typography variant="caption" color="error">請上傳{validExtension.join(', ')}</Typography>
                }
                { error && 
                    <Typography variant="caption" color="error"></Typography>
                }
            </Box>
        </Box> 
    )
}

export default AvatorUploader;