import { Card, CardContent, Chip, Collapse, Divider, Grid } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import useStyles from "../../styles/teamStyle";
import ExpandableIconButton from "../common/ExpandableIconButton";
import MemberExperienceText from "../common/MemberExperienceText";
import MemberExperienceTitle from "../common/MemberExperienceTitle";
import { MemberNameText, MemberNumberText, MemberTitleText } from "../common/MemberTexts";

const TeamMemberItem = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { data } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return !isEmpty(data)
    ? data.map((person, index) => {
        return (
          <Grid
            key={`team_member_${index}`}
            item
            lg={4}
            md={6}
            sm={6}
            xs={12}
            className={`${classes.teamMemberWrap} ${classes.personWrap}`}
          >
            <Card className={classes.teamMemberItem} variant="outlined">
              <img className={classes.teamMemberImg} src={person.img} alt={person.name} />
              <CardContent>
                <Grid container spacing={3} alignItems="center" justify="center">
                  <Grid item md={4} sm={12} xs={6}>
                    <MemberNameText name={person.name} />
                  </Grid>
                  <Grid item md={8} sm={12} xs={6}>
                    <MemberTitleText title={person.title} />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <MemberNumberText number={person.number} />
                  </Grid>
                  <Grid item sm={12} className={classes.teamMemberKeyword}>
                    {person.keyword.map((info, index) => {
                      return <Chip label={info} key={`keyword_${index}`} variant="outlined" />;
                    })}
                  </Grid>
                </Grid>
              </CardContent>

              <ExpandableIconButton expanded={expanded} onClick={handleExpandClick} />

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.memberExperiences}>
                  <Grid item sm={12} className={classes.personExperienceHighlight}>
                    {person.working_experience.length > 0 && <MemberExperienceTitle title="經歷" />}
                    {person.working_experience.map((info, index) => {
                      return <MemberExperienceText index={index} info={info} />;
                    })}
                    {person.education_experience.length > 0 && (
                      <>
                        <Divider />
                        <MemberExperienceTitle title="學歷" />
                      </>
                    )}
                    {person.education_experience.map((info, index) => {
                      return <MemberExperienceText index={index} info={info} />;
                    })}
                    {person.certification.length > 0 && (
                      <>
                        <Divider />
                        <MemberExperienceTitle title="資格認證" />
                      </>
                    )}
                    {person.certification.map((info, index) => {
                      return <MemberExperienceText index={index} info={info} />;
                    })}
                  </Grid>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
        );
      })
    : "";
};

export default TeamMemberItem;
