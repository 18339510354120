import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import useStyles from '../styles/homeStyle'
import PCHeader from '../components/common/PCHeader';
import KeyVisual from '../components/home/KeyVisual';
import HomeFullWidthSlideshow from '../components/home/HomeFullWidthSlideshow';
import HomeServiceSection from '../components/home/HomeServiceSection';
import DMSection from '../components/home/DMSection';
import Footer from '../components/common/Footer';
import Map from '../components/home/Map';

const Home = () => {
    const classes = useStyles();
    const formRef = useRef();
    const [ ifScrollToForm, setIfScrollToForm ] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setIfScrollToForm(location.hash === '#form');
    }, [location])

    return(
        <div>
            <PCHeader formRef={formRef} ifScrollToForm={ifScrollToForm} />
            <KeyVisual /> 
            <div className={classes.slideshow}>
                <HomeFullWidthSlideshow />
            </div>
            <HomeServiceSection />
            <DMSection />
            <Map />
            <Footer />
        </div>
    )
}

export default Home;