import React from "react";
import useStyles from "../../styles/footerStyle";
import { Grid, Typography } from "@material-ui/core";
import fullLogo from "../../assets/images/full-logo.svg";
import { locationInfo } from "../../config/locationAndUrl";

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.footer} justifyContent="space-between">
      <Grid item xl={7} lg={5} md={5} sm={12} xs={12} className={classes.logo}>
        <a href="/">
          <img src={fullLogo} alt="拾月拾日" />
        </a>
        <Typography variant="caption" color="textSecondary">
          © 2020 拾月拾日 10/10 PSY
        </Typography>
      </Grid>
      <Grid item xl={5} lg={7} md={7} sm={12} xs={12} className={classes.location}>
        <Grid container justifyContent="flex-end" spacing={3}>
          {locationInfo.map((o, i) => (
            <Grid item key={i} xl={4} lg={4} md={4} sm={12} xs={12}>
              <Typography variant="h5" color="textSecondary">
                {o.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {o.address}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {o.phone}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
