import React from "react";
import { Link } from "react-router-dom";
import useStyles from "../../styles/navStyle";
import { Grid, Typography, Button } from "@material-ui/core";
import textLogo from "../../assets/images/logo.svg";
import BottomNavigation from "../common/BottomNav";

const PCHeader = props => {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        className={classes.header}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item lg={3} xl={2} className={classes.logo}>
          <a href="/">
            <img src={textLogo} alt="拾月拾日" />
          </a>
        </Grid>
        <Grid item lg={9} xl={10}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item className={classes.topNav}>
              <Button component={Link} to="/service">
                <Typography variant="body2" color="textSecondary">
                  服務及收費
                </Typography>
              </Button>
            </Grid>
            <Grid item className={classes.topNav}>
              <Button component={Link} to="/team">
                <Typography variant="body2" color="textSecondary">
                  團隊介紹
                </Typography>
              </Button>
            </Grid>
            <Grid item className={classes.topNav}>
              <Button component={Link} to="/space">
                <Typography variant="body2" color="textSecondary">
                  環境介紹
                </Typography>
              </Button>
            </Grid>
            <Grid item className={classes.topNav}>
              <Button component={Link} to="/about">
                <Typography variant="body2" color="textSecondary">
                  關於我們
                </Typography>
              </Button>
            </Grid>
            {/* <Grid item className={classes.topNav}>
              <Button
                className="MuiButton-text inline-flex"
                component={Link}
                to="/booking"
              >
                <span className={classes.breatheDot} />
                <Typography variant="body2" color="textPrimary">
                  線上預約
                </Typography>
              </Button>
            </Grid> */}
            <Grid item>
              <a
                className="MuiButton-text inline-flex"
                rel="noopener noreferrer"
                href="https://www.facebook.com/1010psy.tw/"
                target="_blank"
              >
                <Typography variant="body2" color="textSecondary">
                  FACEBOOK
                </Typography>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <BottomNavigation />
    </div>
  );
};

export default PCHeader;
