import oneImg from "../assets/images/one.svg";
import togetherImg from "../assets/images/together.svg";
import changingImg from "../assets/images/changing.svg";
import growImg from "../assets/images/grow.svg";
import buildImg from "../assets/images/build.svg";
import assessImg from "../assets/images/assess.svg";
import feedbackImg from "../assets/images/feedback.svg";
import artImg from "../assets/images/art.svg";

export const serviceFeeObjArray = [
  {
    name: "個別心理治療",
    img: oneImg,
    description:
      "最近睡不好？覺得好久沒有感到開心了？常常覺得很緊張，不知道在焦慮什麼？人際關係讓你煩躁？在感情上遇到困境？或者，在職場上感到受挫？生活上遇到的困擾，不知道找誰說？",
    description2: "這裡能讓您安心訴說，協助您整理困擾，讓心安適下來。",
    fee: ["1800-2000元/50分鐘（中文）", "2500元/50分鐘（英文）"],
  },
  {
    name: "早期療育",
    img: growImg,
    description:
      "他像是條蟲，總是坐不住，閒不下來!孩子好像不太理人，是不是有自閉症?每次不開心就摔東⻄，好好說，他也聽不進去！去幼稚園半年了，每天都還是哭著進教室，孩子是不是太黏我了?",
    description2:
      "在安全、安心的環境裡，我們協助孩子瞭解並穩定其身心發展，亦可透過專諮詢解答您心中的疑惑，讓我們與您獨特的孩子一同成長。",
    fee: [
      "心理治療 1800元/50分鐘、團體1000元/50分鐘",
      "藝術療育 個別1600元/50分鐘、團體1000元/50分鐘",
      "(本所為台中市社會局特約單位，可申請台中市早療補助)",
    ],
  },
  {
    name: "伴侶/婚姻/家族治療",
    img: togetherImg,
    description:
      "彼此的關係不知怎麼的，走到了這裡。不知該繼續、原諒或者僵持著，許多的傷害存在於不理解之中，但彼此都想修復，卻不知怎麼做。",
    description2:
      "試著邀請您的伴侶/另一半/家人，透過專業會談，去降低關係內衝突，突破僵化的關係，深化緊密關係，發揮關係的力量。",
    fee: ["伴侶與婚姻治療 2000元-3000元/次", "家族治療 3000元/次"],
  },
  {
    name: "大腦復健",
    img: buildImg,
    description:
      "運用臨床工具進行大腦功能檢查，包含注意力、記憶力、執行功能、計畫能力等。客製專屬的大腦功能訓練療程，以利功能恢復、疾病適應以及職業重建。",
    description2: "適用對象：腦傷、中風、失智、癲癇、ADHD、發展遲緩等。",
    fee: ["腦功能檢測+結果解說 3000元/2小時", "療程依功能評估後而定"],
  },
  {
    name: "藝術治療",
    img: artImg,
    description:
      "在安全而自在的環境裡，藉由創造性藝術表達去協助探索不同的心理議題。透過藝術媒材，讓您更了解個人的性格、內在需求、興趣或情感狀態。",
    description2: null,
    fee: ["藝術治療:2000元/50分鐘 (媒材費另收)", "伴侶/兩人以上課程:3000元/90分鐘 (媒材費另收)"],
  },
  {
    name: "心理衡鑑",
    img: assessImg,
    description:
      "依據標準化的測驗為您進行包括不同項度的評估，包括認知功能/人格情緒/學習障礙等，依不同需求與目標調整評估內容。",
    description2: null,
    fee: ["3000元-10000元 (依項目而定)"],
  },
  {
    name: "生理/神經回饋治療",
    img: feedbackImg,
    description:
      "運用非侵入性之生理神經回饋儀器，檢測大腦功能、身體指標，並客製大腦與身體療程。增進自我覺察、放鬆以及專注等。",
    description2:
      "適用對象：注意力不集中、高壓力、高焦慮、慢性疲勞、自律神經失調、緊張性頭痛肩頸痛等。",
    fee: ["2000元-2400元/次 (需視個別需求而調整)"],
  },
  {
    name: "高齡心理照護/長照到宅服務",
    img: changingImg,
    description:
      "退休後，覺得有點空虛、失去成就感?面對接下來的人生，覺得徬徨不安?擔心自己的身體越來越差？擔心自己是否會造成孩子們的負擔？",
    description2:
      "在人生的下半場，仍然會有許多煩惱，與專業助人工作者一同討論，讓您不再孤單面對，照顧自己的心理與照顧身體一樣重要。",
    fee: ["2000元/50分鐘", "(本所為台中市政府長照B單位，可申請長照補助)"],
  },
];

export const homeServiceFeeObjArray = [
  {
    name: "個別心理治療",
    img: oneImg,
    description:
      "最近睡不好？覺得好久沒有感到開心了？常常覺得很緊張，不知道在焦慮什麼？人際關係讓你煩躁？在感情上遇到困境？或者，在職場上感到受挫？生活上遇到的困擾，不知道找誰說？",
    description2: "這裡能讓您安心訴說，協助您整理困擾，讓心安適下來。",
    fee: ["1800-2000元/50分鐘（中文）", "2500元/50分鐘（英文）"],
  },
  {
    name: "早期療育",
    img: growImg,
    description:
      "他像是條蟲，總是坐不住，閒不下來!孩子好像不太理人，是不是有自閉症?每次不開心就摔東⻄，好好說，他也聽不進去！去幼稚園半年了，每天都還是哭著進教室，孩子是不是太黏我了?",
    description2:
      "在安全、安心的環境裡，我們協助孩子瞭解並穩定其身心發展，亦可透過專諮詢解答您心中的疑惑，讓我們與您獨特的孩子一同成長。",
    fee: [
      "心理治療 1800元/50分鐘、團體1000元/50分鐘",
      "藝術療育 個別1600元/50分鐘、團體1000元/50分鐘",
      "(本所為台中市社會局特約單位，可申請台中市早療補助)",
    ],
  },
  {
    name: "伴侶/婚姻/家族治療",
    img: togetherImg,
    description:
      "彼此的關係不知怎麼的，走到了這裡。不知該繼續、原諒或者僵持著，許多的傷害存在於不理解之中，但彼此都想修復，卻不知怎麼做。",
    description2:
      "試著邀請您的伴侶/另一半/家人，透過專業會談，去降低關係內衝突，突破僵化的關係，深化緊密關係，發揮關係的力量。",
    fee: ["伴侶與婚姻治療 2000元-3000元/次", "家族治療 3000元/次"],
  },
];
