import { omit } from "lodash";
import app from "../config/base";

export const addPsychologist = async (values) => {
  const filteredValues = omit(values, ["avatar"]);
  await app.firestore().collection("psychologists").doc().set(filteredValues);
};

export const editPsychologist = async (values) => {
  const filteredValues = omit(values, ["avatar"]);
  await app.firestore().collection("psychologists").doc(values.id).set(filteredValues);
};

export const deletePsychologist = async (id) => {
  await app.firestore().collection("psychologists").doc(id).delete();
  window.location.reload();
};

export const addSupervisior = async (values) => {
  const filteredValues = omit(values, ["avatar"]);
  await app.firestore().collection("supervisiors").doc().set(filteredValues);
};

export const editSupervisior = async (values) => {
  const filteredValues = omit(values, ["avatar"]);
  await app.firestore().collection("supervisiors").doc(values.id).set(filteredValues);
};

export const deleteSupervisior = async (id) => {
  await app.firestore().collection("supervisiors").doc(id).delete();
  window.location.reload();
};
