import { makeStyles } from '@material-ui/core/styles';

const drawerOpenWidth = 200;
const drawerCloseWidth = 72
const appBarHeight = 64;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& .MuiList-root': {
      '& .MuiListItem-root': {
          padding: '14px 0px 14px 22px',
      },
      '& .MuiSvgIcon-root': {
          fill: '#666',
      },
    },
  },
  appBarLogo: {
    height: appBarHeight,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    '& img': {
      height: '21%',
      marginLeft: theme.spacing(1),
    }
  },
  appBarLogo_text: {
    position: 'relative',
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
  appBar: {
    background: '#fff',
    zIndex: `${theme.zIndex.drawer + 1} !important`,
    borderBottom: '1px solid #ddd',
    '& .MuiToolbar-root': {
      paddingRight: '8px !important',
    },
  },
  burgerBtn: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  drawer: {
    width: drawerOpenWidth,
    whiteSpace: 'nowrap', // keep the words and width of drawer list same all the time
    "& a": {
      textDecoration: 'none !important',
    },
  },
  drawerOpen: {
    width: drawerOpenWidth,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& a .MuiListItemText-root': {
        opacity: 1,
        transition: theme.transitions.create(['opacity'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
  },
  drawerClose: {
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: drawerCloseWidth,
    '& a .MuiListItemText-root': {
        opacity: 0,
        transition: theme.transitions.create(['opacity'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
  },
  drawerList: {
    marginTop: `${appBarHeight}px !important`,
  },
}));

export default useStyles;