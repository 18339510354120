import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    pageTitleAndSubtitle: {
        '& h4': {
            marginBottom: theme.spacing(1)
        },
        '& h6': {
            marginBottom: theme.spacing(2)
        }
    }
}))

export default useStyles;